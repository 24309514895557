<template>
  <div class="cookies-policy-page">
    <Header/>
    <div class="rf-cookies-policy-title-div rf-white-background pt-5 pb-2">
      <b-container>
        <b-row>
          <b-col>
            <h1 class="text title">Cookies policy</h1>
            <div class="cookies-policy-category-text">
              <p class="text">During your browsing on the website  <router-link to="/">www.roofinders.com</router-link> (the “website”), Roofinders S.r.l., as
                data controller, will process your personal data through the use of cookies</p>
              <p class="text">The data controller gives great importance to the protection of your personal data (the “data“)
                and, with this document (the “Cookie Policy“), provides you with all the necessary information
                in order to understand the procedures and purposes of the processing of your data through
                cookies. Please note that this Cookie Policy applies only to the Website, with the exclusion of
                any website to which the user may be redirected via links that may be available on the website.</p>
              <p class="text">In addition, the data controller invites you to read the complete privacy policy provided
                pursuant to Article 13 of Regulation (EU) 2016/679 (the “Regulation“ or “GDPR“)</p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="rf-cookies-policy-category">
      <b-container>
        <h1 class="cookies-policy-category-title">1. Who is the data controller ?</h1>
        <div class="cookies-policy-category-text cookies-policy-full-paragraph-div">
          <p class="text-line">The data controller is <b>Roofinders S.r.l.</b>, with registered office in Milan, Via Alessandro Manzoni
            no 43, VAT 11182550969, in person of its <i>pro tempore</i> legal representative (“<b>Roofinders</b>” or “<b>Data
            Controller</b>”).</p>
          <p class="text-line">You may contact the Data Controller writing at the following email address:
            <a :href="`mailto:${RFData.mail}`">{{RFData.mail}}</a>
          </p>
        </div>
      </b-container>
    </div>
    <div class="rf-cookies-policy-category">
      <b-container>
        <h1 class="cookies-policy-category-title">2. Cookie</h1>
        <div class="cookies-policy-category-text cookies-policy-full-paragraph-div">
          <p class="text-line">Cookies are small text files that are sent to the user’s terminal equipment by visited websites;
            they are stored in the user’s terminal equipment to be then re-transmitted to the websites on
            the user’s subsequent visits to those websites.</p>
          <div>
            <h2 class="cookies-policy-category-secondary-title"><i>2.1 What types of cookies do we use and what is the legal basis?</i></h2>
            <p class="text-line">Roofinders uses the following types of cookies:</p>
            <div class="cookies-policy-category-list">
              <ul>
                <li v-for="cookieType in cookiesTypes" class="text-line" :key="cookieType.name">
                  <div>
                    <p class="text-line"><b>{{cookieType.name}}</b>, {{cookieType.description}}</p>
                    <p class="text-line">{{cookieType.prior}}</p>
                  </div>
                </li>
              </ul>
            </div>
            <p class="text-line">In addition, we use both session cookies, which are automatically deleted when you exit the
              browser, and persistent cookies, which remain installed in your terminal equipment for a
              predetermined period.</p>
            <p class="text-line">This website uses the following cookies:</p>
            <div>
              <p class="text-line cookie-title">First party cookies</p>
              <div class="privacy-policy-category-list">
                <ul>
                  <li class="text-line" v-for="cookie in firstPartyCookies" :key="cookie.name">{{cookie.name}}</li>
                </ul>
              </div>
            </div>
            <div>
              <p class="text-line cookie-title">Third party cookies</p>
              <div class="privacy-policy-category-list">
                <ul>
                  <li class="text-line" v-for="cookie in thirdPartyCookies" :key="cookie.name">{{cookie.name}}</li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <h2 class="cookies-policy-category-secondary-title"><i>2.2 How to disable cookies?</i></h2>
            <p class="text-line">We provide below information on how to disable/delete cookies, depending on the type of
              browser used:</p>
            <div class="cookies-policy-category-list">
              <ul>
                <li v-for="browser in disableBrowserCookiesLinks" class="text-line" :key="browser.browser_name">{{browser.browser_name}}, [<a v-bind:href="browser.link">{{browser.link}}</a>]</li>
              </ul>
            </div>
            <p class="text-line">In any case, we would remind you that certain technical cookies are essential for the proper
              functioning of the website and that their uninstallation may prevent you from browsing the
              website.</p>
            <p class="text-line">We would also like to inform You that it is possible to use your browser settings to delete
              cookies already installed on your device.</p>
          </div>
        </div>
      </b-container>
    </div>
    <div class="rf-cookies-policy-category">
      <b-container>
        <h1 class="cookies-policy-category-title">3. Which are your rights as data subject?</h1>
        <div class="cookies-policy-category-text">
          <p class="text-line">In your quality as data subject, according to the conditions laid down by the GDPR, you have
            the right to:</p>
          <div class="cookies-policy-category-list">
            <ul>
              <li v-for="right in rights" class="text-line" :key="right.description">{{right.description}}</li>
            </ul>
          </div>
          <div class="cookies-policy-full-paragraph-div">
            <p class="text-line">Finally, you may at any time submit a complaint to the Italian Data Protection Authority (<i>Garante per la protezione dei dati personali</i>).</p>
            <p class="text-line">In order to obtain further information on your rights, you may contact the Data Controller at
              the e-mail address specified in previous paragraph 1.</p>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'

export default {
  name: 'CookiesPolicy',
  components: {
    Header
  },
  data() {
    return {
      RFData:{
        mail : 'privacy@roofinders.com'
      },
      cookiesTypes:[
        {
          name : 'technical cookies',
          description : 'which are used for the sole purpose of carrying out the transmission of a communication on an electronic communication network, or as strictly necessary to provide the service requested by the user of the website.',
          prior : 'Your prior consent is not required for the installation and use of technical cookies.'
        },
        {
          name : 'analytics cookies',
          description : 'which are used to compile statistics and understand how the website is used by the users.',
          prior : 'Your prior consent is not required for the installation of analytics cookies, since they are\n' +
            'considered equivalent to technical cookies if (i) they are only used to obtain aggregate\n' +
            'statistics and in relation to a single website or a single mobile application; (ii) at least\n' +
            'the fourth component of the IP address is hidden for third-party cookies; (iii) the third\n' +
            'party does not combine these analytics cookies with other processing (for instance,\n' +
            'customer files or statistics regarding visits to other websites) or transmit them to third\n' +
            'parties.'
        },
        {
          name : 'profiling cookies',
          description : 'which are used to create profiles of the user and to send advertising messages in line with the preferences expressed by the user while browsing the web.',
          prior : 'Your prior consent is required for the installation of profiling cookies.'
        },
      ],
      firstPartyCookies:[
        {
          name : 'RooFinders techcnicals'
        }
      ],
      thirdPartyCookies:[
        {
          name : 'Google Analytics'
        },
        {
          name : 'Google Adwords'
        },
        {
          name : 'WordPress'
        },
        {
          name : 'Advertising and Social Media: Instagram, Facebook, Linkedin, Youtube'
        },

      ],
      disableBrowserCookiesLinks:[
        {
          browser_name : 'Google Chrome',
          link : 'https://support.google.com/accounts/answer/61416?hl=it'
        },
        {
          browser_name : 'Mozilla Firefox',
          link : 'https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences'
        },
        {
          browser_name : 'Internet Explorer',
          link : 'https://support.microsoft.com/it-it/help/17442/windows-internet-explorer-delete-manage-cookies'
        },
        {
          browser_name : 'Safari',
          link : 'https://support.apple.com/it-it/guide/safari/sfri11471/mac'
        }
      ],
      rights:[
        {
          description : 'Access your data and obtain a copy of the same'
        },
        {
          description : 'Ask for the rectification and the update of your data, where inaccurate or incomplete'
        },
        {
          description : 'Ask, under certain circumstances, for the erasure of your data or the limitation of the\n' +
            'processing of the data'
        },
        {
          description : 'Ask for data portability'
        },
        {
          description : 'Oppose the processing of your data (if applicable)'
        },
        {
          description : 'Withdraw the consent, where the processing of the data is based on the above mentioned condition of lawfulness'
        }
      ]
    }
  },
  computed: {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.cookies-policy-page{

  .text {
    @include font-style($montserrat, 'medium', $font-16);
    color: $dark-blue;
    margin: 20px 0 0 0;

    &.suptitle {
      @include font-style($montserrat-bold, 'bold', $font-34);
      margin: 0;
    }
    &.title {
      @include font-style($montserrat-bold, 'bold', $font-44);
      margin: 0;
    }
  }

  .rf-cookies-policy-category{
    position: relative;
    padding-top: 2%;

    .cookies-policy-category-title{
      @include font-style($montserrat-bold, 'bold', $font-26);
      color: $dark-blue;
    }

    .cookies-policy-category-secondary-title{
      @include font-style($montserrat-bold, 'bold', $font-20);
      color: $dark-blue;
      padding-bottom: 2%;
    }

    .cookies-policy-category-text{
      margin-top: 40px;

      .text-line {
        @include font-style($montserrat, 'medium', $font-16);
        color: $dark-blue;
        margin: 0;
      }
      .cookie-title{
        font-weight: bold;
        font-style: italic;
      }
    }

    .cookies-policy-category-list{
      padding-top: 3%;
    }

    .cookies-policy-full-paragraph-div{
      p{
        padding-bottom: 2%;
      }
    }

    .cookies-policy-table{
      .table{
        color : $dark-blue;
      }
    }

  }

  .rf-cookies-policy-title-div {

    .cookies-policy-category-text {
      margin-top: 40px;

      .text-line {
        @include font-style($montserrat, 'medium', $font-16);
        color: $dark-blue;
        margin: 0;
      }
    }
  }

}

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  .cookies-policy-page {
    .rf-cookies-policy-category {
      .cookies-policy-category-text {
        width: 80%;
      }

    }
    .rf-cookies-policy-title-div {
      .cookies-policy-category-text {
        width: 80%;
      }

    }
  }
}

</style>
